<template>
    <BT-Blade-Item
        bladeName="standing-customer-order"
        :bladesData="bladesData"
        :canMinimize="false"
        :canPin="false"
        @edit="edit"
        navigation="standing-customer-orders"
        :onGetSave="getOrderToSave"
        :onPullSuccessAsync="orderPulled"
        :onNew="newOrder"
        @sourceChanged="updateBlade"
        title="Standing Order"
        :actualUsedHeight="175"
        :defaultBladeWidth="500">
        <template v-slot:notFound="ite">
            <BT-Select-List-Box
                @change="id => { selectCustomer(id, ite.refresh) }"
                v-model="mAgreementID"
                label="Customer"
                itemText="buyer.companyName"
                itemValue="id"
                isSelecting
                height="400"
                isEditing
                navigation="customers"
                :params="{ properties: 'ID,Buyer' }"
                :searchProperties="['buyer.companyName']">
                <template v-slot="{ item }">
                    <v-list-item-avatar>
                        <v-icon color="primary">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-subtitle>
                        {{ item.buyer.companyName }}
                    </v-list-item-subtitle>
                </template>
            </BT-Select-List-Box>
        </template>
        <template v-slot="d">
            <v-list-item v-if="d.item != null && d.item.buyer != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(d.item.buyerID )" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ d.item.buyer.companyName }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else-if="currentPolicy != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(currentPolicy.buyerID )" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ currentPolicy.buyerName }}</v-list-item-title>
            </v-list-item>
            
            <BT-Field-String
                v-model="d.item.standingOrderName"
                label="Standing Order Name"
                :isEditing="d.data.isEditing || d.data.isNew" />

            <v-list-item dense>
                <v-list-item-content v-if="!d.data.isEditing && !d.data.isNew || currentPolicy == null">
                    <v-list-item-subtitle>Destination</v-list-item-subtitle>
                    <v-list-item-title>{{ d.item.location | toLocationLine }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <BT-Select
                        v-model="d.item.locationID"
                        :items="possibleLocations"
                        textFilter="toLocationLine"
                        itemValue="id"
                        label="Destination" />
                </v-list-item-content>
            </v-list-item>

            <BT-Field-Checkbox
                v-model="d.item.isBuyerCourier"
                label="Customer Prefers To Organizer Courier"
                :isEditing="d.data.isEditing || d.data.isNew" />

            <BT-Field-Checkbox
                v-model="d.item.isRequestPickup"
                label="Customer Will Pickup"
                :isEditing="d.data.isEditing || d.data.isNew" />

            <v-expansion-panels v-model="panelV" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>Regularity</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <BT-List
                            v-if="(currentPolicy == null || currentPolicy.orderingArrangement !== 'Slotted') && isLengthyArray(d.item.orderSlots)"
                            height="auto"
                            :items="d.item.orderSlots">
                            <template v-slot="{ item }">
                                <v-list-item-icon><v-icon small class="success--text">mdi-check-bold</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.slotName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.isOn">(Next: {{ item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>Off</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-List>

                        <BT-List
                            v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                            height="auto"
                            :items="filteredSlots">
                            <template v-slot="e">
                                <v-list-item-action>
                                    <v-btn
                                        @click="toggleSlot(e.item, d.item, d.isNew)"
                                        :class="e.item.isSelected ? 'success--text' : 'red--text'"
                                        :loading="e.item.loadingCount > 0"
                                        icon
                                        small>
                                        <v-icon small>{{ e.item.isSelected ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ e.item.slotName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="e.item.isOn">(Next: {{ e.item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>Off</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-List>
                        
                        <div v-else-if="(currentPolicy == null || currentPolicy.orderArrangement !== 'Slotted')">
                            <BT-Field-Switch
                                v-model="d.item.isOn"
                                falseText="Off"
                                :isEditing="d.data.isEditing || d.data.isNew"
                                label="Status"
                                trueText="On" />

                            <BT-Field-Date
                                v-if="d.item.isOn"
                                v-model="d.item.nextAttentionDate"
                                label="Next Occurrence"
                                shortDateAndTime />
                        
                            <BT-Cron
                                v-model="d.item.cronExpression"
                                :isEditing="d.data.isEditing || d.data.isNew"
                                label="Regularity"
                                leadTimeInHours
                                leadTimeLeftLabel="Delivery Lead Time (Hours)"
                                editExpression
                                showExpression />

                            <BT-Cron-Adjustments
                                v-model="d.item.adjustments"
                                :cronExpression="d.item.cronExpression"
                                leadTimeLeftLabel="Delivery Lead Time (Hours)"
                                :isEditing="d.data.isEditing || d.data.isNew"
                                label="Adjustments"
                                leadTimeInHours />
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <BT-Blade-Expansion-Items
                    :canRefresh="false"
                    :canSelect="false"
                    :headers="[
                        { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', isIcon: true },
                        { text: 'Description', value: 'description', display: true, subtitle: 1 }]"
                    hideActions
                    :items="d.item.orderItems"
                    title="Line Items">
                    <template v-slot:description="{ item }">
                        {{ item.description != null ? item.description : item.product.productName }}
                    </template>
                    <template v-slot:actions="{ open }">
                        <v-btn
                            v-if="(d.data.isEditing || d.data.isNew) && open"
                            icon
                            small
                            @click.stop="mDrawer = !mDrawer">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <!-- <BT-Order-Items-Table-Dialog
                            v-if="(d.data.isEditing || d.data.isNew) && open"
                            :block="false"
                            buttonClass=""
                            :canEditPrice="false"
                            icon="mdi-pencil"
                            :order="d.item"
                            :policy="currentPolicy"
                            small
                            :text="null"
                            @ok="i => { updateOrder(d.item, i) }" /> -->

                        <BT-Order-Items-Dialog
                            v-if="(d.data.isEditing || d.data.isNew) && open"
                            :block="false"
                            buttonClass=""
                            icon="mdi-pencil"
                            :order="d.item"
                            :policy="currentPolicy"
                            small
                            :text="null"
                            @ok="i => { updateOrder(d.item, i) }" />
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>

            <div v-if="currentPolicy != null">
                <h4 class="text-right ma-3">Subtotal: {{ d.item.subTotal | toCurrency }}</h4>
                <h4 class="text-right mx-3 mb-1">GST: {{ d.item.taxTotal | toCurrency }}</h4>
                
                <v-divider />
                
                <h3 class="text-right mx-3 mt-2">Total: {{ d.item.amountTotal | toCurrency }}</h3>
            </div>

            <BT-Snack v-model="msg" />
            
            <BT-Order-Items-Drawer
                v-if="d.data.isEditing || d.data.isNew"
                :drawerToggle="mDrawer"
                :isEditing="d.data.isEditing || d.data.isNew"
                :order="d.item"
                label="Order Items"
                :policy="currentPolicy"
                @ok="i => { updateOrder(d.item, i) }" />

        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { firstBy } from 'thenby';

export default {
    name: 'Standing-Customer-Order-Blade',
    components: {
        BTOrderItemsDialog: () => import('~components/BT-Order-Items-Dialog.vue'),
        BTOrderItemsDrawer: () => import('~components/BT-Order-Items-Drawer.vue'),
        // BTOrderItemsTableDialog: () => import('~components/BT-Order-Items-Table-Dialog.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
    },
    data: function() {
        return {
            currentPolicy: null,
            dateFrom: null,
            dateRules: null,
            filteredSlots: [],
            isRestrictedOrdering: true,
            loadingCount: 0,
            mAgreementID: null,
            mDrawer: false,
            msg: null,
            panelV: [1],
            refreshToggle: false,
            showToggle: false
        }
    },
    props: {
        bladesData: { type: Object, default: null },
    },
    computed: {
        possibleLocations() {
            return this.currentPolicy != null ? this.currentPolicy.possibleLocations : [];
        }
    },
    mounted() {
        if (this.$route.params.id == 'new') {
            //is new
            if (this.$route.query.agreementID != null) {
                this.mAgreementID = this.$route.query.agreementID;
            }
            else {
                this.showToggle = !this.showToggle;
            }
        }
        else {
            this.mAgreementID = '1';
        }
    },
    methods: {
        startLoading() {
            this.loadingCount += 1;
            this.$forceUpdate();
        },
        endLoading() {
            this.loadingCount -= 1;
            this.$forceUpdate();
        },
        getOrderToSave(bladeData, order) {
            order.orderItems = order.orderItems.filter(x => x.productID != null);
            return order;
        },
        minDate(d) {
            return this.isMinDate(d);
        },
        async edit(bladeData, order) {
            console.log('editing');
            if (order != null && order.id != null) {
                var resData = await this.$BlitzIt.api.getById('suppliers', order.supplyAgreementID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                var policy = resData.data.data;

                    if (policy.orderingArrangement === 'Slotted') {
                    this.filteredSlots = [];
                    policy.slots.forEach(slot => {
                        this.filteredSlots.push({
                            loading: false,
                            isSelected: order.orderSlots.some(orderSlot => orderSlot.id == slot.id), //&& slot.standingOrderIDs.some(y => y == order.id),
                            ...slot
                        });
                    });

                    if (order != null && this.isLengthyArray(order.orderSlots)) {
                        order.orderSlots.forEach(eSlot => {
                            if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
                                this.filteredSlots.unshift({
                                    loading: false,
                                    isSelected: true,
                                    ...eSlot
                                });
                            }
                        })
                    }
                }
                else if (policy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }

                this.currentPolicy = policy;
            }
        },
        async newOrder() {
            console.log('new standing order');
            
            return {
                id: null,
                isBuyerCourier: false,
                locationID: null,
                location: null,
                orderItems: [],
                supplyAgreementID: null,
                orderSlotIDs: []
            }
        },
        async orderPulled(order, refresh, bladeData) {
            this.currentPolicy = null;
            this.dateFrom = null;
            this.filteredSlots = [];
            this.dateRules = null;
            this.isRestrictedOrdering = true;
            
            try {
                this.startLoading();

                if (order != null && (order.id == null || order.id == 'new')) {
                    console.log('newing');
                    //new
                    var agrID = null;

                    if (order.supplyAgreementID != null) {
                        agrID = order.supplyAgreementID;
                    }
                    else if (bladeData != null && bladeData.data != null && bladeData.data.agreementID != null) {
                        agrID = bladeData.data.agreementID;
                    }
                    else if (this.mAgreementID != null) {
                        agrID = this.mAgreementID;
                    }

                    if (agrID == null) {
                        return null;
                    }

                    //get policy
                    var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                    var policy = resData.data.data;

                    order.isBuyerCourier = policy.isRequirePickup || policy.isPreferPickup;
                    order.locationID = policy.defaultLocationID;
                    order.location = policy.defaultLocation;
                    order.supplyAgreementID = agrID;

                    if (policy.orderingArrangement === 'Slotted') {
                        this.filteredSlots = [];
                        policy.slots.forEach(slot => {
                            this.filteredSlots.push({
                                loading: false,
                                isSelected: order != null && order.id != null && order.orderSlots.some(orderSlot => orderSlot.id == slot.id), //&& slot.standingOrderIDs.some(y => y == order.id),
                                ...slot
                            });
                        });

                        if (order != null && this.isLengthyArray(order.orderSlots)) {
                            order.orderSlots.forEach(eSlot => {
                                if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
                                    this.filteredSlots.unshift({
                                        loading: false,
                                        isSelected: true,
                                        ...eSlot
                                    });
                                }
                            })
                        }
                    }
                    else if (policy.requireLeadTime) {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }
                    else {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }

                    this.currentPolicy = policy;
                }
                else if (order != null) {
                    order.orderItems.sort(firstBy(x => x.product.sortNumber));
                    order.orderItems = order.orderItems.map(x => Object.assign({}, x, { description: x.product ? x.product.productName: null, unitPrice: 0 }));
                }

                return order;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.endLoading();
            }
        },
        selectCustomer(id, refreshCallback) {
            this.mAgreementID = id;
            this.loaded = true;

            refreshCallback();
        },
        async toggleSlot(slot, item, isNew) {
            if (isNew) {
                var e = item.orderSlotIDs.findIndex(x => x == slot.id);
                if (e >= 0) {
                    slot.isSelected = false;
                    item.orderSlotIDs.splice(e, 1);
                }
                else {
                    slot.isSelected = true;
                    item.orderSlotIDs.push(slot.id);
                }
            }
            else {
                try {
                    slot.loadingCount += 1; // = true;
                    this.$forceUpdate();

                    var url = slot.isSelected ? '/post/Remove' : '/post/Add';

                    await this.$BlitzIt.api.post('standing-customer-orders', { orderSlotID: slot.id, standingOrderID: item.id }, null, url);

                    slot.isSelected = !slot.isSelected;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    slot.loading = false;
                    this.$forceUpdate();
                }
            }
        },
        updateBlade() {
            this.mAgreementID = null;
        },
        updateOrder(originalOrder, updatedOrder) {
            originalOrder.orderItems = updatedOrder.orderItems;
            originalOrder.taxTotal = updatedOrder.taxTotal;
            originalOrder.subTotal = updatedOrder.subTotal;
            originalOrder.amountTotal = updatedOrder.amountTotal;
        }
     }
}
</script>